import {
  format,
  isEqual,
  isThisYear,
  startOfTomorrow,
  differenceInDays,
  formatRelative,
  parse,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isMatch,
  isSameDay,
  isBefore,
  startOfDay as startOfDayFns,
  addMonths as addMonthsFn,
  differenceInMinutes,
} from 'date-fns';
import { it } from 'date-fns/locale';
import { maybeTextWithPrefix } from './text';

export const DateFormats = {
  SHORT_DATE: 'dd/MM/yyyy',
  LONG_DATE: 'd MMMM yyyy',
  SHORT_DATE_TIME: 'dd/MM/yyyy HH:mm',
  LONG_DATE_TIME: 'd MMMM yyyy, HH:mm',
};

export const isValidDate = (date?: Date | string | number): boolean =>
  date instanceof Date && !isNaN(date.getTime());

export const formatDate = (date: number | Date, dateFormat: string): string => {
  return format(date, dateFormat, { locale: it });
};

/**
 * Parsa una data in formato ISO o yyyy-MM-dd e la formatta nel formato richiesto.
 * Il parsing non considera l'orario: se necessario riformattare anche l'orario, usare `reformatDateTime`.
 * Per i valori non validi viene restituito il valore di default.
 * @param dateString La rappresentazione testuale di una data in formato ISO o yyyy-MM-dd
 * @param dateFormat Il formato in cui si vuole visualizzare la data. Default: 'dd-MM-yyyy'
 * @param emptyValue Il valore di default da visualizzare se la data è vuota o non valida. Default: '-'
 * @returns La data formattata nel formato richiesto.
 */
export const reformatDate = (
  dateString?: string,
  dateFormat = 'dd-MM-yyyy',
  emptyValue = '-',
): string => {
  if (!dateString) {
    return emptyValue;
  }
  const date = parseDayString(dateString);
  return isValidDate(date) ? formatDate(date, dateFormat) : emptyValue;
};

export const reformatDateTime = (dateTimeString?: string): string => {
  const dateTime = parseDate(dateTimeString || '', "yyyy-MM-dd'T'HH:mm:ssXXX");
  if (isValidDate(dateTime)) {
    return formatDate(dateTime, 'd MMMM yyyy, HH:mm');
  }
  return '-';
};

const formatPeriod = (
  dateStart: Date,
  dateEnd: Date,
  timeStart?: string,
  timeEnd?: string,
): string => {
  const sDate = formatDate(dateStart, 'dd/MM/yy');
  const eDate = formatDate(dateEnd, 'dd/MM/yy');
  const sTime = prefixComma(timeStart);
  const eTime = prefixComma(timeEnd);

  return `${sDate}${sTime} - ${eDate}${eTime}`;
};

export const smartFormatPeriod = (
  dateStart: string | Date,
  dateEnd: string | Date,
  timeStart?: string,
  timeEnd?: string,
): string => {
  const sDate = parseDayString(dateStart);
  const eDate = parseDayString(dateEnd);
  const sTime = prefixComma(timeStart);
  const eTime = prefixComma(timeEnd);
  const formatMonth = (date: Date) => formatDate(date, 'dd MMM');

  if (isEqual(sDate, eDate)) {
    const dashTime = prefixDash(timeEnd);
    return `${formatMonth(sDate)}${sTime}${dashTime}`;
  }
  if (isThisYear(sDate) && isThisYear(eDate)) {
    return `${formatMonth(sDate)}${sTime} - ${formatMonth(eDate)}${eTime}`;
  }

  return formatPeriod(sDate, eDate, timeStart, timeEnd);
};

const prefixComma = maybeTextWithPrefix(', ');
const prefixDash = maybeTextWithPrefix(' - ');

export const toISODate = (date: Date | number): string =>
  isValidDate(date) ? formatDate(date, 'yyyy-MM-dd') : '';

export const toAPIFormat = (date: Date | number): string =>
  isValidDate(date) ? formatDate(date, 'dd-MM-yyyy') : '';

export const addDays = (date: Date, days: number): Date => {
  const resultDate = new Date(date);
  resultDate.setDate(resultDate.getDate() + days);
  return resultDate;
};

export const isSameDate = (dateLeft: Date, dateRight: Date): boolean => {
  return isSameDay(dateLeft, dateRight);
};

export const formatTime = (date: string): string => {
  const time = date.split('T')[1].split(':');
  const hour = time[0];
  const min = time[1];
  return `${hour}:${min}`;
};

export const getTomorrow = (): Date => startOfTomorrow();

export const getAfterTomorrow = (): Date => addDays(startOfTomorrow(), 1);

/**
 * Unisce data e tempo in un unico Date object.
 * @param dateString Data in formato ISO. es. yyyy-MM-dd, yyyy-MM-ddTHH:mm:ss
 * @param timeString Ore, minuti (e secondi). es. HH:mm, HH:mm:ss
 * @returns la data composta dai due parametri.
 */
export const fromDateAndTime = (
  dateString: string,
  timeString?: string,
): Date => {
  const date = parseDayString(dateString);
  if (timeString) {
    const timeParts = timeString.split(':').map(unit => parseInt(unit));
    date.setHours(timeParts[0], timeParts[1]);
  }
  return date;
};

export const diffInDays = (end: Date, start: Date): number => {
  return differenceInDays(end, start);
};

export const formatRelativeDate = (date: Date | number): string => {
  const now = new Date();
  const minute = 60,
    hour = minute * 60,
    day = hour * 24;
  const timestamp = typeof date === 'number' ? date : date.getTime();
  const delta = (now.getTime() - timestamp) / 1000;
  if (delta < minute) {
    return 'meno di un minuto fa';
  } else if (delta < minute * 2) {
    return '1 minuto fa';
  } else if (delta < hour) {
    return Math.floor(delta / minute) + ' minuti fa';
  } else if (delta < hour * 2) {
    return '1 ora fa';
  } else if (delta < day) {
    return Math.floor(delta / hour) + ' ore fa';
  }
  return formatRelative(date, now, { locale: it });
};

export const parseDate = (dateString: string, formatString: string): Date => {
  return parse(dateString, formatString, new Date());
};

export function parseDayString(date: string | Date) {
  if (date instanceof Date) {
    return date;
  } else if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
    return parseDate(date.split('T')[0], 'yyyy-MM-dd');
  } else if (date.match(/\d{4}-\d{2}-\d{2}/)) {
    return parseDate(date, 'yyyy-MM-dd');
  }

  return new Date(date);
}

export const getWeekInterval = (date: Date): { start: Date; end: Date } => {
  const start = startOfWeek(date, { locale: it });
  const end = endOfWeek(date, { locale: it });
  return { start, end };
};

export const eachDayInWeekInterval = (date: Date): Date[] => {
  return eachDayOfInterval(getWeekInterval(date));
};

export const isISODateString = (dateString: string): boolean =>
  isMatch(dateString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") ||
  isMatch(dateString, "yyyy-MM-dd'T'HH:mm:ssxxx") ||
  isMatch(dateString, "yyyy-MM-dd'T'HH:mm:ss") ||
  isMatch(dateString, 'yyyy-MM-dd');

export const safeParseDate = (dateString: string): Date | undefined => {
  const date = new Date(dateString);
  const result = isNaN(date.getTime()) ? undefined : date;
  return result;
};
export const startWeek = (date: Date): Date => {
  return startOfWeek(date, { locale: it, weekStartsOn: 1 });
};
export const endWeek = (date: Date): Date => {
  return endOfWeek(date, { locale: it, weekStartsOn: 1 });
};
export const safeIsBefore = (
  d1: string | null | undefined,
  d2: string | null | undefined,
) => {
  if (!d1 || !d2) return false;
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();
  return isBefore(date1, date2);
};

export function dateDifferenceInMinutes(
  oraInizio: string,
  oraFine: string,
  data: string,
): number {
  const formatoDataOra = 'yyyy-MM-dd HH:mm';
  const inizio = parseDate(`${data} ${oraInizio}`, formatoDataOra);
  const fine = parseDate(`${data} ${oraFine}`, formatoDataOra);

  return differenceInMinutes(fine, inizio);
}

export const formatDurationInHoursAndMinutes = (
  totalMinutes: number,
): string => {
  if (isNaN(totalMinutes)) {
    return '-h';
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0 && minutes === 0) {
    return '0h';
  }
  const formattedHours = hours > 0 ? `${hours}h` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}min` : '';

  return `${formattedHours} ${formattedMinutes}`.trim();
};

export const isBeforeDate = isBefore;
export const addMonths = addMonthsFn;
export const startOfDay = startOfDayFns;
